import { createRouter, createWebHistory } from 'vue-router'
import Layout from '@/layout'

export const routes = [

	{
		path: '/',
		name: 'stock',
		redirect: '/Revenue/index',
		component: Layout,
		meta: { title: '', icon: 'form' },
		children: [

			{
				name: '上市每月營收',
				path: 'Revenue',
				redirect: '/Revenue',
				icon: 'cil-calculator',
				children: [
					{
						component: () => import('../views/Revenue/List'),
						name: 'Revenuelist',
						path: '/Revenue/index',
						meta: { title: '', subtitle: '瀏覽', icon: 'form' },
					},
					{
						name: 'Revenueedit',
						path: '/Revenue/edit/:id',
						component: () => import('../views/Revenue/Edit'),
						meta: { title: '', subtitle: '編輯', icon: 'form' },
					},
					{
						name: 'Revenuecreate',
						path: '/Revenue/create',
						component: () => import('../views/Revenue/Create'),
						meta: { title: '', subtitle: '新增', icon: 'form' },
					}
				]
			},
			//#A#

		]
	}
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
})

export default router

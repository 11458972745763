<template>
  <footer class="main-footer">
    玉山2024面試考題- Edit By David(Email: xqcm168@gmail.com)
    <div class="float-right d-none d-sm-inline-block">
      <b>開發日期</b> 2024-08-11
    </div>
  </footer>
</template>

<script>
export default {
  name : 'Footer'
}
</script>

<style>

</style>
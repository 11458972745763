<template>
    <body class="hold-transition sidebar-mini layout-fixed">
      <div class="wrapper">


          <section class="content">
            <div class="container-fluid">
                <router-view></router-view>
            </div>
          </section>          
          <Footer></Footer>
\        
      </div>
    </body>
  </template>
  
  <script>
  import "@/../node_modules/admin-lte/plugins/select2-bootstrap4-theme/select2-bootstrap4.min.css"
  import "@/../node_modules/admin-lte/plugins/select2/css/select2.min.css"
  import Footer from "@/dashboard/Footer.vue";

  export default {
    name: "App",
    components: {
      Footer
    },
    methods: {
      debuglog(msg) {
        console.log(msg);
      }
    },
    mounted() {

      // var test = this.$store.state.token;      
      // if(test=="") this.$router.push({name : "login"});
    }
  };
  </script>
  
  <style>
  </style>
  